<template>
  <div leave-greeting>
    <div class="area">
      <p v-t:_.complete></p>
      <p v-t:_.thanks></p>
      <ColorButton type="red" @click="goHome">{{ $t('_.goHome') }}</ColorButton>
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';

export default {
  name: 'LeaveGreeting',
  lexicon: 'account.leaveGreeting',
  components: { ColorButton },
  methods: {
    goHome() {
      this.routeName('Home');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[leave-greeting] { .flex-center;
  .area { .ib; .tc; .c(#eee);
    p { .fs(24); .mb(34); }
    p + p { .fs(18); }
    button { .w(295); }
  }
}
</style>
